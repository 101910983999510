import request from '@/utils/request';

// 获取卡券列表
export function getUserCouponList(params) {
  return request({
    url: `/user/coupons`,
    method: 'get',
    params
  });
}

// 领取卡券  /organization/coupons/active/{groupUserId}
export function getMealCouponAPI(groupUserId, data) {
  return request({
    url: `/organization/coupons/active/${groupUserId}`,
    method: 'post',
    data
  });
}

// 购买消费券
export function buyCouponAPI(params) {
  return request({
    url: `/mealconsume/h5/buyMealConsume`,
    method: 'get',
    params
  });
}

// 获取二维码
export function getQrCodeAPI(params) {
  return request({
    url: `/mealconsume/h5/QRCode`,
    method: 'get',
    params
  });
}

// 退券
export function refundCouponAPI(params) {
  return request({
    url: `/mealconsume/h5/refundMealConsume`,
    method: 'get',
    params
  });
}
