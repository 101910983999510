<template>
  <div>
    <div class="container" v-if="!loading">
      <div class="container_title">
        <div class="left" @click="toBack">
          <img style="width: 15px; height: 25px" :src="cancel" alt />
        </div>
        <div class="right">我的餐券</div>
      </div>
      <!-- <div class="container_title">
        <div class="left" @click="toBack">
          <img style="width: 15px; height: 25px" :src="cancel" alt />
        </div>
        <div class="right">我的餐券</div>
      </div> -->
      <div style="display: flex; flex-direction: column; margin-bottom: 16px">
        <div class="container_tab">
          <div class="left">
            <div
              class="box"
              :class="{
                active: fliter.type === 0
              }"
              @click="tabChange(0)"
            >
              我的餐券
            </div>
          </div>
          <div class="right">
            <div
              class="box"
              :class="{
                active: this.fliter.type === 1
              }"
              @click="tabChange(1)"
            >
              购买餐券
            </div>
          </div>
        </div>
        <div
          class="select-type"
          v-if="this.fliter.type === 0 && couponList.length > 0"
        >
          <div class="select-button" @click="openTypeDialog">
            <div class="name">{{ type }}</div>
            <img src="../../assets/icon-select-type.png" alt="" />
          </div>
        </div>
      </div>
      <div class="container_left" v-if="this.fliter.type === 0">
        <div
          class="content"
          v-for="(item, index) in currentUseList"
          :key="item.code"
        >
          <div
            class="content_title"
            v-if="index === 0 && getChangeTime(item.endTime) > timeNow"
          >
            可使用
          </div>
          <div class="img-box">
            <img
              class="bg-image"
              :src="currentBackgroundImageCanUse(item)"
              alt=""
            />
            <div @click="openQrCode(item)" class="detail">
              <div class="detail_left">
                <div class="top">
                  <div class="left">
                    <p
                      :style="
                        getChangeTime(item.endTime) < timeNow
                          ? 'background:#f3f3f3'
                          : ''
                      "
                    >
                      <img
                        :style="{
                          width:
                            getChangeTime(item.endTime) < timeNow
                              ? '18px'
                              : '32px',
                          height:
                            getChangeTime(item.endTime) < timeNow
                              ? '18px'
                              : '32px'
                        }"
                        :src="
                          getChangeTime(item.endTime) < timeNow
                            ? iconExpired
                            : iconCard
                        "
                        alt
                      />
                    </p>
                    <p
                      :style="
                        getChangeTime(item.endTime) < timeNow
                          ? 'color:#999'
                          : ''
                      "
                      class="coupon-name"
                    >
                      {{ item.name }}
                    </p>
                  </div>
                  <div
                    class="right"
                    :style="
                      getChangeTime(item.endTime) < timeNow ? 'color:#999' : ''
                    "
                  >
                    {{ item.code }}
                  </div>
                </div>
                <div class="left_bottom">
                  <div class="bottom_top">
                    <div
                      class="left"
                      :style="
                        getChangeTime(item.endTime) < timeNow
                          ? 'color:#999'
                          : ''
                      "
                    >
                      有效期:
                    </div>
                    <div
                      class="right"
                      :style="
                        getChangeTime(item.endTime) < timeNow
                          ? 'color:#999'
                          : ''
                      "
                    >
                      {{ item.startTime.slice(0, 10) }} ~
                      {{ item.endTime.slice(0, 10) }}
                    </div>
                  </div>
                  <div class="bottom_footer">
                    <div
                      class="left"
                      :style="
                        getChangeTime(item.endTime) < timeNow
                          ? 'color:#999'
                          : ''
                      "
                    >
                      适用餐段:
                    </div>
                    <div
                      class="right"
                      :style="
                        getChangeTime(item.endTime) < timeNow
                          ? 'color:#999'
                          : ''
                      "
                    >
                      {{ item.mealTimeName }}
                    </div>
                  </div>
                  <div class="bottom_footer" v-if="item.type === 0">
                    <div
                      class="left"
                      :style="
                        getChangeTime(item.endTime) < timeNow
                          ? 'color:#999'
                          : ''
                      "
                    >
                      可用次数:
                    </div>
                    <div
                      class="right"
                      :style="
                        getChangeTime(item.endTime) < timeNow
                          ? 'color:#999'
                          : ''
                      "
                    >
                      {{ item.avaliableNum }}次
                    </div>
                  </div>
                  <div class="bottom_footer" v-if="item.type === 2">
                    <div
                      class="left"
                      :style="
                        getChangeTime(item.endTime) < timeNow
                          ? 'color:#999'
                          : ''
                      "
                    >
                      可用张数:
                    </div>
                    <div
                      class="right"
                      :style="
                        getChangeTime(item.endTime) < timeNow
                          ? 'color:#999'
                          : ''
                      "
                    >
                      {{ item.buyNumber - item.checkNumber }}张
                    </div>
                  </div>
                </div>
              </div>
              <div class="detail_right">
                <div class="right_in" v-if="item.type === 1">
                  <div class="title">次数券</div>
                  <div
                    class="top"
                    :style="
                      getChangeTime(item.endTime) < timeNow ? 'color:#999' : ''
                    "
                  >
                    {{ item.avaliableNum }}次
                  </div>
                  <div
                    class="bottom"
                    :style="
                      getChangeTime(item.endTime) < timeNow ? 'color:#999' : ''
                    "
                  >
                    剩余次数
                  </div>
                </div>
                <div class="right_in" v-if="item.type === 0">
                  <div class="title">折扣券</div>
                  <div
                    class="top"
                    :style="
                      getChangeTime(item.endTime) < timeNow ? 'color:#999' : ''
                    "
                  >
                    {{ (item.discount / 10).toFixed(1) }}
                    <span
                      v-if="item.type === 0"
                      style="font-size: 12px; color: #c7000b"
                    >
                      折
                    </span>
                  </div>
                </div>
                <div class="right_in" v-if="item.type === 2">
                  <div class="title">消费券</div>
                  <img
                    src="../../assets/icon-qrcode-tem.png"
                    style="width: 60px; height: 60px"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentUnUseList.length > 0" class="unUseListTitle">
          不可使用
        </div>
        <div
          class="content"
          v-for="(item, index) in currentUnUseList"
          :key="item.code"
        >
          <div class="img-box">
            <img
              class="bg-image"
              :src="currentBackgroundImageCanUse(item, 'unUse')"
              alt=""
            />
            <div class="detail">
              <div class="detail_left">
                <div class="top">
                  <div class="left">
                    <p :style="'background:#f3f3f3'">
                      <img :src="iconExpired" alt />
                    </p>
                    <p :style="'color:#999'" class="coupon-name">
                      {{ item.name }}
                    </p>
                  </div>
                  <div class="right" :style="'color:#999'">
                    {{ item.code }}
                  </div>
                </div>
                <div class="left_bottom">
                  <div class="bottom_top">
                    <div class="left" :style="'color:#999'">有效期:</div>
                    <div class="right" :style="'color:#999'">
                      {{ item.startTime.slice(0, 10) }} ~
                      {{ item.endTime.slice(0, 10) }}
                    </div>
                  </div>
                  <div class="bottom_footer">
                    <div class="left" :style="'color:#999'">适用餐段:</div>
                    <div class="right" :style="'color:#999'">
                      {{ item.mealTimeName }}
                    </div>
                  </div>
                  <div class="bottom_footer" v-if="item.type === 0">
                    <div class="left" :style="'color:#999'">可用次数:</div>
                    <div class="right" :style="'color:#999'">
                      {{ item.avaliableNum }}次
                    </div>
                  </div>
                  <div class="bottom_footer" v-if="item.type === 2">
                    <div class="left" :style="'color:#999'">可用张数:</div>
                    <div class="right" :style="'color:#999'">
                      {{ item.buyNumber - item.checkNumber }}张
                    </div>
                  </div>
                </div>
              </div>
              <div class="detail_right">
                <div class="right_in" v-if="item.type === 1">
                  <div class="disable">次数券</div>
                  <div class="top" :style="'color:#999'">
                    {{ item.avaliableNum }}次
                  </div>
                  <div class="bottom" :style="'color:#999'">剩余次数</div>
                </div>
                <div class="right_in" v-if="item.type === 0">
                  <div class="title disable">折扣券</div>
                  <div class="top" :style="'color:#999'">
                    {{ (item.discount / 10).toFixed(1) }}
                    <span
                      v-if="item.type === 0"
                      style="font-size: 12px; color: #999"
                    >
                      折
                    </span>
                  </div>
                </div>
                <div class="right_in" v-if="item.type === 2">
                  <div class="title disable">消费券</div>
                  <div class="mask"></div>
                  <img src="../../assets/icon-qrcode-tem.png" alt="" />
                </div>
              </div>
              <div class="expired">
                <img :src="currentTypeImg(item)" alt />
              </div>
            </div>
          </div>
        </div>
        <div class="notData" v-if="isList">
          <img
            style="width: calc(100vw - 40px)"
            src="../../assets/01.png"
            alt
          />
        </div>
      </div>

      <div class="container_right" v-if="fliter.type === 1">
        <div class="content" v-for="(item, index) in couponList" :key="index">
          <!-- <div
            class="content_title"
            v-if="
              (index === 0 && getChangeTime(item.endTime) < timeNow) ||
              (couponList[index - 1] &&
                getChangeTime(couponList[index - 1].endTime) > timeNow &&
                getChangeTime(item.endTime) < timeNow)
            "
          >
            不可购买
            <span
              style="
                color: #666666;
                font-size: 13px;
                font-weight: 400;
                margi-right: 12px;
              "
            >
              仅显示近半年记录
            </span>
          </div> -->
          <div class="content_title" v-if="index === 0">可购买</div>
          <div class="img-box">
            <img class="bg-image" :src="currentBackgroundImage(item)" alt="" />
            <div class="detail" @click.stop="clickFn(item)">
              <div class="detail_left">
                <div class="top">
                  <div class="left">
                    <img class="useCard" :src="iconCard" alt />

                    <p>
                      {{ item.name }}
                    </p>
                  </div>
                  <div class="right">
                    {{ item.code }}
                  </div>
                </div>
                <div class="left_bottom">
                  <div
                    class="bottom_top"
                    v-if="
                      item.activeStatus === 2 &&
                      getChangeTime(item.endTime || item.payEndTime) > timeNow
                    "
                  >
                    <div class="left">购买时间:</div>
                    <div class="right">
                      {{ item.payStartTime.slice(0, 10) }} ~
                      {{ item.payEndTime.slice(0, 10) }}
                    </div>
                  </div>
                  <div class="bottom_top">
                    <div class="left">有效期:</div>
                    <div class="right">
                      {{
                        item.type === 2
                          ? item.day + '天'
                          : item.startTime.slice(0, 10) +
                            ' ~ ' +
                            item.endTime.slice(0, 10)
                      }}
                    </div>
                  </div>
                  <div class="bottom_footer">
                    <div class="left">适用餐段:</div>
                    <div class="right">
                      {{ item.mealTimeName }}
                    </div>
                  </div>
                  <div class="bottom_footer">
                    <div class="left">可用次数:</div>
                    <div class="right">{{ item.num }}次</div>
                  </div>
                </div>
              </div>
              <div class="detail_right">
                <div class="right_in">
                  <div class="type">
                    {{
                      item.type === 0
                        ? '折扣券'
                        : item.type === 1
                        ? '次数券'
                        : '消费券'
                    }}
                  </div>
                  <div class="top" style="text-align: center">
                    {{
                      item.type !== 0
                        ? '￥' + item.amount / 100
                        : (item.discount / 10).toFixed(1)
                    }}
                    <span
                      v-if="item.type === 0"
                      style="font-size: 12px; color: #c7000b"
                    >
                      折
                    </span>
                  </div>

                  <!-- <div
                    class="bottom"
                    style="text-align: center"
                    :style="
                      getChangeTime(item.endTime) < timeNow ? 'color:#999' : ''
                    "
                  >
                    {{ item.activeStatus === 2 ? '金额' : '折' }}
                  </div> -->
                </div>
              </div>
              <!-- <div
                class="expired"
                style="right: -20px; top: -20px"
                v-if="getChangeTime(item.endTime) < timeNow"
              >
                <img
                  style="width: 95px; height: 95px"
                  :style="item.activeStatus === 1 ? 'height:90px;' : ''"
                  :src="item.activeStatus === 1 ? unclaimable : purchase"
                  alt
                />
              </div> -->
              <div
                class="detail_mask"
                v-if="
                  item.activeStatus === 1 &&
                  getChangeTime(item.endTime) > timeNow
                "
              >
                <div class="left"></div>
                <div class="right" @click.stop="getMealCouponFn(item)">
                  <p>立即激活</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="notData" v-if="isList">
          <img
            style="width: calc(100vw - 40px)"
            src="../../assets/02.png"
            alt
          />
        </div>
      </div>
      <!-- 购买弹框 -->
      <van-popup
        v-model="isShow"
        position="bottom"
        :style="{ height: '365px' }"
      >
        <div class="payment">
          <div class="payment_top">
            <img
              @click="isShow = false"
              src="../../assets/icon-pay-close.png"
              alt=""
            />
            <span class="title">支付信息</span>
          </div>
          <div class="amount">
            <div class="amount_top">
              {{ (selectItem.amount / 100).toFixed(2) }}
            </div>
            <div class="amount_bottom">订单金额(元)</div>
          </div>
          <div class="payType">
            <!-- <van-radio-group v-model="payType" checked-color="rgba(10,193,171)">
            <van-cell-group>
              <van-cell title="卡内余额" clickable @click="radio = 1">
                <template #right-icon>
                  <van-radio :name="1" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group> -->
            <div class="item">
              <div class="label">支付方式</div>
              <div class="value">卡内余额</div>
            </div>
            <div class="item">
              <div class="label">购买数量</div>
              <van-stepper
                theme="round"
                button-size="22"
                integer
                min="1"
                max="99"
                v-model="num"
                :disabled="selectItem.type === 2 ? false : true"
              />
            </div>
          </div>
          <div class="payment_footer">
            <div class="btn" @click="payCard">立即支付</div>
          </div>
        </div>
      </van-popup>
      <!-- 二维码弹窗 -->
      <van-dialog
        v-model="isShowQrCode"
        :show-confirm-button="false"
        :width="316"
        closeOnClickOverlay
      >
        <div class="dialog-container" v-if="qrCode">
          <div class="qr-code-title">
            <div class="left">
              <img src="../../assets/icon-title-qrcode.png" alt="" />
              <span>核销码</span>
            </div>
            <img
              @click="isShowQrCode = false"
              src="../../assets/icon-close-qrcode.png"
              alt=""
            />
          </div>
          <div class="qr-code-head">扫描下方二维码核销{{ qrItem.name }}</div>
          <div class="qr-code-body">
            <img :src="qrCode" alt="" />
          </div>
          <div class="code">
            {{ qrItem.code }}
          </div>
          <div class="button" @click="isShowDialog = true">退券</div>
        </div>
        <van-overlay
          style="display: flex; align-items: center; justify-content: center"
          v-else
          :show="loading"
        >
          <van-loading color="#1989fa" />
        </van-overlay>
      </van-dialog>
      <!-- 切换类型弹窗 -->
      <!-- 对接员 -->
      <van-popup v-model="isShowTypeDialog" position="bottom">
        <van-picker
          show-toolbar
          :columns="typeList"
          @confirm="onConfirmType"
          @cancel="isShowTypeDialog = false"
          value-key="label"
        />
      </van-popup>
      <!-- 提示弹窗 -->
      <div class="message">
        <van-dialog
          class="dialog"
          v-model="isShowDialog"
          showCancelButton
          cancelButtonColor="#999999"
          confirmButtonColor="#FA5050"
          confirmButtonText="确定"
          @cancel="isShowDialog = false"
          @confirm="lossCoupon"
        >
          <template>
            <div class="head">
              <div class="title">温馨提示</div>
              <div class="content">确定退掉该餐券吗?</div>
            </div>
          </template>
        </van-dialog>
      </div>
    </div>
    <van-overlay
      style="display: flex; align-items: center; justify-content: center"
      v-else
      :show="loading"
    >
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import cancel from '@/assets/cancel.png';
import {
  getUserCouponList,
  getMealCouponAPI,
  buyCouponAPI,
  getQrCodeAPI,
  refundCouponAPI
} from '@/api/coupon';
import { Toast } from 'vant';
import iconCard from '@/assets/icon_card.png';
import expired from '@/assets/expired.png';
import iconExpired from '@/assets/icon_expired.png';
import purchase from '@/assets/purchase.png';
import unclaimable from '@/assets/unclaimable.png';
import QRCode from 'qrcode';

export default {
  data() {
    return {
      cancel,
      unclaimable,
      expired,
      iconExpired,
      purchase,
      iconCard,
      finished: false,
      loading: false,
      couponList: [],
      pagination: {
        current: 1,
        size: 1000,
        total: 0
      },
      fliter: {
        type: 0
      },
      timeNow: '',
      isShow: false,
      payType: 1,
      selectItem: {},
      isList: false,
      num: 1,
      qrItem: {},
      isShowQrCode: false,
      qrCode: '',
      isShowDialog: false,
      unUseList: [],
      useList: [],
      currentUseList: [],
      currentUnUseList: [],
      timer: null,
      isShowTypeDialog: false,
      typeList: [
        {
          label: '全部',
          value: 'all'
        },
        {
          label: '可使用',
          value: 0
        },
        {
          label: '不可使用',
          value: 1
        }
      ],
      type: '全部'
    };
  },
  watch: {
    isShowQrCode: {
      handler: async function (val) {
        if (val) {
          if (this.timer) {
            clearInterval(this.timer);
          }
          await this.getQrCode();
          this.timer = setInterval(async () => {
            await this.getQrCode();
          }, 60000);
        } else {
          clearInterval(this.timer);
        }
      },
      immediate: true
    }
  },
  async created() {
    this.timeNow = Date.now();
    await this.getCouponList();
  },
  methods: {
    onConfirmType(item) {
      console.log(item);
      if (item.value === 'all') {
        this.currentUnUseList = this.unUseList;
        this.currentUseList = this.useList;
      } else if (item.value === 0) {
        this.currentUnUseList = [];
        this.currentUseList = this.useList;
      } else if (item.value === 1) {
        this.currentUseList = [];
        this.currentUnUseList = this.unUseList;
      }
      this.type = item.label;
      this.isShowTypeDialog = false;
    },
    openTypeDialog() {
      this.isShowTypeDialog = true;
    },
    async lossCoupon() {
      try {
        const res = await refundCouponAPI({
          consumeBuyId: this.qrItem.consumeBuyId
        });
        if (res.code === 0) {
          Toast.success({
            message: '退券成功',
            duration: 1000
          });
        } else {
          Toast.fail({
            message: res.message,
            duration: 1000
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        await this.getCouponList();
        this.isShowDialog = false;
        this.isShowQrCode = false;
      }
    },
    async openQrCode(item) {
      if (item.type !== 2) {
        return;
      } else {
        this.qrItem = item;

        this.isShowQrCode = true;
      }
    },
    async getQrCode() {
      try {
        const res = await getQrCodeAPI({
          consumeBuyId: this.qrItem.consumeBuyId
        });
        const { code, data, message } = res;
        if (code === 0) {
          const generateQR = await QRCode.toDataURL(data);
          this.qrCode = generateQR;
          this.time = JSON.parse(res.data).expireIn;
          // await this.countDown()
        } else {
          Toast.fail({
            message,
            duration: 1000
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    toBack() {
      this.$router.back();
    },

    async getCouponList() {
      try {
        this.loading = true;
        const id = this.$store.getters.userId;
        const data = {
          ...this.pagination,
          userId: id,
          ...this.fliter
        };
        this.couponList = [];
        const res = await getUserCouponList(data);
        if (res.code === 0) {
          res.data.records.map((item) => {
            this.couponList.push(item);
          });
          const today = dayjs();
          console.log(today, 'today');
          if (this.fliter.type === 1) {
            this.couponList = this.couponList.filter((item) => {
              if (item.type === 0) {
                // 比较 endTime
                const endTime = dayjs(item.endTime);
                return endTime.isAfter(today) || endTime.isSame(today);
              } else {
                // 比较 payEndTime
                const payEndTime = dayjs(item.payEndTime);
                const payStartTime = dayjs(item.payStartTime);
                console.log(
                  payEndTime.isAfter(today) && payStartTime.isBefore(today),
                  item,
                  'payEndTime'
                );
                return (
                  payEndTime.isAfter(today) && payStartTime.isBefore(today)
                );
              }
            });
            // 根据item的createTime排序,时间越晚的排在前面
            this.couponList.sort((a, b) => {
              return dayjs(b.createTime) - dayjs(a.createTime);
            });
            console.log(this.couponList, 'couponList');
          } else {
            const unUseList = this.couponList.filter((item) => {
              if (
                (item.type === 2 &&
                  (item.refundNumber > 0 ||
                    item.checkNumber === item.buyNumber ||
                    dayjs(item.endTime).isBefore(today) ||
                    dayjs(item.startTime).isAfter(today))) ||
                (item.type !== 2 &&
                  (dayjs(item.endTime).isBefore(today) ||
                    dayjs(item.startTime).isAfter(today))) ||
                (item.avaliableNum === 0 &&
                  (item.type === 1 || item.type === 0))
              ) {
                console.log(item, 'item');
                return item;
              }
            });
            //  useList为couponList中除了unUseList的数据
            const useList = this.couponList.filter((item) => {
              if (
                (item.type === 2 &&
                  item.refundNumber === 0 &&
                  item.checkNumber !== item.buyNumber &&
                  dayjs(item.endTime).isAfter(today) &&
                  dayjs(item.startTime).isBefore(today)) ||
                (item.type === 0 &&
                  dayjs(item.endTime).isAfter(today) &&
                  dayjs(item.startTime).isBefore(today) &&
                  item.avaliableNum > 0) ||
                (item.type === 1 &&
                  item.avaliableNum > 0 &&
                  dayjs(item.endTime).isAfter(today) &&
                  dayjs(item.startTime).isBefore(today))
              ) {
                return item;
              }
            });
            useList.sort((a, b) => {
              return dayjs(b.createTime) - dayjs(a.createTime);
            });
            this.unUseList = unUseList;
            this.useList = useList;
            this.currentUnUseList = unUseList;
            this.currentUseList = useList;
            console.log('unUseList', unUseList);
            console.log('useList', useList);
          }
          console.log('this.couponList', this.couponList);
          this.pagination = {
            current: res.data.current,
            size: res.data.size,
            total: res.data.total
          };
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          if (this.couponList.length !== 0) {
            this.isList = false;
          } else {
            this.isList = true;
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    getChangeTime(dateString) {
      // var format = 'YYYY-MM-DD HH:mm:ss'
      var dateParts = dateString.split(/[- :]/);
      var utcDate = Date.UTC(
        dateParts[0],
        dateParts[1] - 1,
        dateParts[2],
        dateParts[3],
        dateParts[4],
        dateParts[5]
      );
      return Math.floor(utcDate); // 将毫秒转换为秒
    },
    currentBackgroundImage(item) {
      if (this.getChangeTime(item.payEndTime || item.endTime) > this.timeNow) {
        return require('../../assets/purchase_bkg.png');
      }
      // if (
      //   this.getChangeTime(item.endTime) > this.timeNow &&
      //   item.activeStatus === 2
      // ) {
      //   return require('../../assets/purchase_bkg2.png');
      // }
      // if (
      //   this.getChangeTime(item.endTime) < this.timeNow &&
      //   item.activeStatus === 1
      // ) {
      //   return require('../../assets/purchase_expired.png');
      // }
    },
    currentBackgroundImageCanUse(item, type) {
      if (type === 'unUse') {
        return require('../../assets/icon-bg-expired.png');
      } else {
        console.log(item, 'item');
        if (this.getChangeTime(item?.endTime) < this.timeNow) {
          return require('../../assets/expired_bkg.png');
        }
        if (
          this.getChangeTime(item?.endTime) < this.timeNow &&
          item.activeStatus === 0
        ) {
          return require('../../assets/purchase_expired.png');
        }
        if (item.type === 0) {
          return require('../../assets/purchase_bkg.png');
        }
        if (
          item.type === 1 &&
          this.getChangeTime(item?.endTime) > this.timeNow
        ) {
          return require('../../assets/card_bkg.png');
        }
        if (
          item.type === 2 &&
          this.getChangeTime(item?.endTime) > this.timeNow
        ) {
          return require('../../assets/card_bkg.png');
        }
      }
    },
    currentTypeImg(item) {
      if (item.type === 2 && item.refundNumber > 0) {
        return require('../../assets/icon-type-refund.png');
      } else if (item.type === 2 && item.checkNumber === item.buyNumber) {
        return require('../../assets/icon-type-used.png');
      } else if (
        (item.type === 1 || item.type === 0) &&
        item.avaliableNum === 0
      ) {
        return require('../../assets/icon-type-used.png');
      } else if (dayjs(item.startTime).isAfter(dayjs())) {
        return require('../../assets/icon-type-unUse.png');
      } else if (dayjs(item.endTime).isBefore(dayjs())) {
        return require('../../assets/icon-type-expired.png');
      }
    },
    async tabChange(val) {
      if (this.fliter.type === val) {
        return;
      }
      if (val === 0) {
        this.type = '全部';
      }
      this.pagination = {
        current: 1,
        size: 1000,
        total: 0
      };
      this.fliter.type = val;
      await this.getCouponList();
      this.finished = false;
    },
    getMealCouponFn(item) {
      this.timeNow = Date.now();
      if (this.getChangeTime(item.endTime) < this.timeNow) {
        Toast.error('激活失败(已过期)!');
        return;
      }
      getMealCouponAPI(item.groupUserId).then(async (res) => {
        if (res.code === 0) {
          Toast.success('激活成功!');
          this.pagination = {
            current: 1,
            size: 1000,
            total: 0
          };
          this.finished = false;
          this.couponList = [];
          await this.getCouponList();
        }
      });
    },
    clickFn(item) {
      if (item.activeStatus === 1) {
        return;
      }
      if (
        item.activeStatus === 2 &&
        this.getChangeTime(item.payStartTime) > this.timeNow
      ) {
        Toast('还未到购买时间!');
        return;
      }
      if (this.getChangeTime(item.payEndTime) < this.timeNow) {
        Toast('已过购买时间!');
        return;
      }
      this.selectItem = item;
      this.isShow = true;
    },
    async payCard() {
      if (this.selectItem.type === 2) {
        try {
          this.loading = true;
          const res = await buyCouponAPI({
            consumeId: this.selectItem.id,
            nums: this.num
          });
          if (res.code === 0) {
            this.pagination = {
              current: 1,
              size: 1000,
              total: 0
            };
            Toast.success('购买成功!');
          }
        } catch (err) {
          console.log(err, 'err');
        } finally {
          this.loading = false;
          this.isShow = false;
          await this.getCouponList();
          this.num = 1;
        }
      } else {
        getMealCouponAPI(this.selectItem.groupUserId, {
          amount: this.selectItem.amount,
          type: 1
        }).then(async (res) => {
          if (res.code === 0) {
            this.pagination = {
              current: 1,
              size: 1000,
              total: 0
            };
            this.finished = false;
            this.isShow = false;
            this.couponList = [];
            await this.getCouponList();
            Toast.success('购买成功!');
          }
          this.num = 1;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  font-family: PingFang SC, PingFang SC !important;
  .container_title {
    height: 44px;
    opacity: 1;
    background: rgba(255, 255, 255);
    display: flex;
    align-items: center;
    .left {
      height: 30px;
      margin-left: 13px;
      width: 15px;
      img {
        width: 15px;
        height: 25px;
      }
    }
    .right {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      color: #333333;
    }
  }
  .container_tab {
    height: 45px;
    opacity: 1;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    box-sizing: border-box;
    box-shadow: 0 1px 0 0 #e5e5e5; /* 上边框 */
    .left,
    .right {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .box {
        /* width: 64px; */
        margin: 0 auto;
        height: 45px;
        line-height: 45px;
        color: #666666;
        font-size: 16px;
        font-weight: 400;
      }
      .active {
        color: #fa5050;
        border-bottom: 2px solid #fa5050;
      }
    }
  }
  .container_left,
  .container_right {
    height: calc(100vh - 105px);
    background: #f7f7f7;
    overflow: hidden;
    overflow-y: scroll;
    .content {
      width: calc(100vw - 32px);
      margin: 0 auto;
      padding-bottom: 10px;
      position: relative;
      overflow: hidden;
      .content_title {
        height: 16px;
        opacity: 1;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        font-family: '苹方';
        text-align: left;
        line-height: 16px;
        margin-bottom: 12px;
      }
      .img-box {
        position: relative;
        width: 343px;
        box-shadow: 0 5px 15px 0 #00000014;
        .bg-image {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%; /* 高度跟随盒子的高度变化 */
          width: 100%; /* 宽度保持比例 */
        }
        .detail {
          width: 100%;
          /* overflow: hidden; */
          /* background: url('../../assets/card_bkg.png') no-repeat;
        background-size: cover;
        background-position: center; */
          display: flex;
          position: relative;
          z-index: 99;
          .detail_left {
            width: 248px;
            .top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #e5e5e5;
              box-sizing: border-box;
              padding: 12px 0;
              margin: 0 16px;
              .left {
                display: flex;
                color: #333333;
                font-size: 16px;
                font-weight: 700;
                align-items: center;
                min-width: 100px;
                p {
                  margin: 0;
                  padding: 0;
                  line-height: 32px;
                }
                p:first-child {
                  width: 32px;
                  height: 32px;
                  border-radius: 16px;
                  background: #f7e7e7;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 12px;
                  img {
                    width: 18px;
                    height: 18px;
                  }
                }
                .useCard {
                  margin-right: 12px;
                  width: 32px;
                  height: 32px;
                }
              }
              .right {
                color: #666666;
                font-size: 12px;
                font-weight: 400;
                max-width: 92px;
                word-wrap: break-word; /* 文字超出时自动换行 */
              }
            }
            .left_bottom {
              width: calc(100% - 32px);
              margin: 0 auto;
              padding-bottom: 18px;
              padding-top: 16px;
              .bottom_top,
              .bottom_footer {
                display: flex;
                justify-content: space-between;
                height: 12px;
                .left {
                  font-size: 12px;
                  color: #333333;
                  width: 60px;
                }
                .right {
                  font-size: 10px;
                  color: #666666;
                }
              }
              .bottom_top {
                margin-bottom: 12px;
              }
              .bottom_footer:last-child {
                margin-top: 12px;
              }
            }
          }
          .detail_right {
            width: 95px;
            display: flex;
            justify-content: center;
            align-items: center;
            .right_in {
              // width: 44px;
              position: relative;
              .mask {
                position: absolute;
                bottom: 1px;
                left: 0;
                width: 60px;
                height: 60px;
                background-color: #f9f9f9;
                opacity: 0.8;
                z-index: 99;
              }
              .title {
                color: #666666;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 12px;
                text-align: center;
              }
              .type {
                color: #666666;
                font-size: 14px;
                font-weight: 500;
                font-family: '苹方';
                margin-bottom: 12px;
              }
              .top {
                color: #c7000b;
                font-size: 20px;
              }
              .bottom {
                color: #666666;
                font-size: 10px;
                font-weight: 400;
                margin-top: 12px;
              }
              img {
                width: 60px;
                height: 60px;
              }
            }
          }
          .expired {
            position: absolute;
            right: -10px;
            top: -16px;
            img {
              width: 74px;
              height: 65px;
            }
          }
          .detail_mask {
            width: 100%;
            height: 100%;
            background: url('../../assets/card_mask.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            z-index: 99;
            .left {
              width: calc(100% - 95px);
            }
            .right {
              width: 95px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              font-size: 16px;
              font-weight: 700;
            }
          }
        }
      }
      .newDetail {
        background: url('../../assets/purchase_bkg.png') no-repeat;
        background-size: 100%;
      }
      .newDetail2 {
        background: url('../../assets/purchase_bkg2.png') no-repeat;
        background-size: contain;
        background-position: center;
      }
      .newDetailActive {
        background: url('../../assets/purchase_expired.png') no-repeat;
        background-size: 100%;
      }
      .detailActive {
        background: url('../../assets/expired_bkg.png') no-repeat;
        background-size: 100%;
      }
    }
    .notData {
      width: calc(100vw - 40px);
      margin: 20px auto;
    }
  }
  .container_left {
    height: calc(100vh - 155px);
  }
  .payment {
    .payment_top {
      height: 50px;

      padding-left: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .title {
        color: #666666;
        font-size: 18px;
        font-weight: 400;
        font-family: '苹方';

        margin-left: 118px;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
    .amount {
      border-top: 1px solid rgba(215, 215, 215);
      color: #333333;
      .amount_top {
        text-align: center;
        opacity: 1;
        color: #333333;
        font-size: 36px;
        font-weight: 500;
        font-family: '苹方';
        text-align: center;
        line-height: 36px;
        margin-top: 31px;
      }
      .amount_bottom {
        text-align: center;
        margin-top: 8px;
      }
    }
    .payType {
      margin-top: 31px;
      padding-left: 16px;
      box-sizing: border-box;
      .item {
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 1px 1px 0 #e5e5e5;
        padding-right: 16px;
        .value {
          color: #666666;
          font-size: 16px;
          font-weight: 400;
          font-family: '苹方';
        }
        .label {
          color: #333333;
          font-size: 16px;
          font-weight: 400;
          font-family: '苹方';
        }
      }
    }
    .payment_footer {
      position: fixed;
      bottom: 22px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        width: 343px;
        height: 44px;
        background: #fa5050;
        border-radius: 4px;
        color: white;
        line-height: 44px;
        text-align: center;
      }
    }
  }
}
.coupon-name {
  font-size: 14px;
}

.dialog-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;

  .qr-code-title {
    box-sizing: border-box;
    border-width: 0;
    border-bottom-width: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* 画一条0.5px的边框 */
    position: relative;

    padding: 0 10px 0 16px;
    height: 50px;
    font-size: 16px;
    font-family: 'PingFangSC-Regular, sans-serif';

    font-weight: 500;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .left {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-size: 16px;
        color: #333333;
        margin-left: 8px;
      }
    }
  }

  .qr-code-title::after {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    border-width: 1px;
    border-style: solid;
    transform: scale(0.5, 0.5);
    box-sizing: border-box;
    pointer-events: none;
  }
  .qr-code-head {
    color: #666666;
    font-size: 15px;
    margin-top: 32px;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
  .qr-code-body {
    width: 218px;
    height: 218px;
    box-sizing: border-box;
    margin-top: 22.5px;
    background-image: url('../../assets/icon-border.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 14px 13px 12px 13px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .code {
    margin-top: 16px;
    color: #666666;
    font-size: 15px;
    font-weight: 400;
    font-family: '苹方';
    text-align: center;
    line-height: 15px;
  }
  .button {
    width: 175px;
    height: 40px;
    border-radius: 4px;
    margin-top: 24px;
    opacity: 1;
    background: #c7000b;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    font-family: '苹方';
  }
}
.disable {
  color: #999 !important;
}
.unUseListTitle {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
  margin-bottom: 12px;
}

.select-type {
  width: 375px;
  height: 50px;
  opacity: 1;
  background: #ffffff;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;

  .select-button {
    border-radius: 4px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    padding: 8px 13px;
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    img {
      width: 19px;
      height: 19px;
      margin-left: 4px;
    }
    .name {
      color: #666666;
      font-size: 18px;
      font-weight: 400;
      font-family: '苹方';
    }
  }
}

.message {
  ::v-deep .van-dialog {
    border-radius: 10px;
  }
}

.dialog {
  width: 343px;
  height: 161px;
  border-radius: 10px;
  ::v-deep .van-dialog {
    border-radius: 0;
  }
  .head {
    box-sizing: border-box;
    height: 110px;
    padding-top: 28px;
    text-align: center;

    .title {
      color: #333333;
      font-size: 18px;
      font-family: 'PingFangSC-Regular';
      font-weight: 500;
      line-height: 18px;
    }
    .content {
      font-size: 16px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;
      margin: 24px 0;
      line-height: 16px;
    }
  }
  ::v-deep .van-button__text {
    font-size: 18px;
    font-family: 'PingFangSC-Regular';
    font-weight: 500;
    line-height: 18px;
  }
}
::v-deep .van-dialog {
  border-radius: 0;
}
::v-deep .van-stepper {
  width: 102px;
  height: 22px;
  text-align: right;
}

::v-deep .van-stepper {
  input {
    width: 29px !important;
    font-size: 16px;
    font-weight: 400;
    font-family: '苹方';
    color: #333333;
  }
}

::v-deep .van-stepper__minus {
  color: #c7000b;
  border: 1px solid #c7000b;
}
::v-deep .van-stepper__plus {
  color: #fff;
  background-color: #c7000b;
}

::v-deep .van-stepper__minus--disabled {
  color: #e5e5e5;
  border: 1px solid #e5e5e5;
  opacity: 1 !important;
}

::v-deep .van-stepper__plus--disabled {
  color: #fff;
  background-color: #e5e5e5;
  opacity: 1 !important;

  font-weight: 500;
  line-height: 18px;
}
.content {
  font-size: 16px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #666666;
  margin: 24px 0;
  line-height: 16px;
}

::v-deep .van-button__text {
  font-size: 18px;
  font-family: 'PingFangSC-Regular';
  font-weight: 500;
  line-height: 18px;
}

::v-deep .van-dialog {
  border-radius: 0;
}
::v-deep .van-stepper {
  width: 102px;
  height: 22px;
  text-align: right;
}

::v-deep .van-stepper {
  input {
    width: 29px !important;
    font-size: 16px;
    font-weight: 400;
    font-family: '苹方';
    color: #333333;
  }
}
::v-deep .van-picker__toolbar {
  height: 50px;
  background-color: #f1f1f1;
  .van-picker__cancel {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
  .van-picker__confirm {
    font-size: 16px;
    font-weight: 500;
    color: #fa5050;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
}
::v-deep .van-picker-column__item {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #333333;
  font-family: 'PingFangSC-Regular, sans-serif';
}
::v-deep .van-stepper__minus {
  color: #c7000b;
  border: 1px solid #c7000b;
}
::v-deep .van-stepper__plus {
  color: #fff;
  background-color: #c7000b;
}

::v-deep .van-stepper__minus--disabled {
  color: #e5e5e5;
  border: 1px solid #e5e5e5;
  opacity: 1 !important;
}

::v-deep .van-stepper__plus--disabled {
  color: #fff;
  background-color: #e5e5e5;
  opacity: 1 !important;
}
</style>
